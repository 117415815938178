import React from "react";
import FormLogo from "../elements/FormLogo";
import ordr_frm_ppt from "../../css/images/ordr_frm_ppt.png";
import logo_name from "../../css/images/logo_name.png";
import of_ftr_grph from "../../css/images/of_ftr_grph.png";
import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { useFormik } from "formik";
import { Formik} from "formik";
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import formatDate from "../elements/DateFormat";


const OrderNow = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const userLoginOrNot = localStorage.getItem("BearerToken");
  if (userLoginOrNot == null) {
    navigate("/login");
  }

  const [puppetImages1, setPuppetImages1] = useState(null);
  const [puppetImages2, setPuppetImages2] = useState(null);
  const [backgroundImageType, setBackgroundImageType] = useState({background_type: "image"});
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backImageId, setBackgroundImageId] = useState(0);
  const [addMorePuppetChecked, setAddMorePuppetChecked] = useState();
  const [orderConfirmationChecked, setOrderConfirmationIsChecked] = useState(false);
  const [termsConditionChecked, setTermsConditionChecked] = useState(false);
  const [videoLength, setvideoLength] = useState({ video_length: 30 });
  const [basicPrice, setBasicPrice] = useState(0);
  const [additionalPuppetPrice, setAdditionalPuppetPrice] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxCalculate, setTaxCalculate] = useState({type: "amount"});

  const [totalPrice, setTotalPrice] = useState(0);
  const [deliverFormat, setDeliverFormat] = useState({ deliver_format: "online_viewing_link" });
  const [deliverFormatPrice, setDeliverFormatPrice] = useState(0);
  const [data, setData] = useState( JSON.parse(localStorage.getItem("billing_info") || "{}") );
  // Get the current date
  const purchaseDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD

  // Calculate the date for 5 days from now
  let daysToAdd = 5;
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + daysToAdd);
  let deliveryDate = nextDate.toISOString().slice(0, 10);
  const nextDay = nextDate.getDay();

  // Calculate the number of days to add based on the current day
  if (nextDay === 6) {
    // Saturday (0-indexed)
    daysToAdd = 7;
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + daysToAdd);
    deliveryDate = nextDate.toISOString().slice(0, 10);
  } else if (nextDay === 0) {
    // Sunday (0-indexed)
    daysToAdd = 6;
    const nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + daysToAdd);
    deliveryDate = nextDate.toISOString().slice(0, 10);
  }

  const handleLoclalStorage = (e) => {
    const billingInfo = JSON.parse(localStorage.getItem("billing_info") || "{}");
    const { name, value } = e.target;
    billingInfo[name] = value; 
    if(!billingInfo.purchase_date || !billingInfo.delivery_date || !billingInfo.videoLength || !billingInfo.deliverFormat || !billingInfo.backgroundType){
      billingInfo.purchase_date = purchaseDate;
      billingInfo.delivery_date = deliveryDate;
      billingInfo.videoLength = videoLength.video_length;
      billingInfo.deliverFormat = deliverFormat.deliver_format;
      billingInfo.backgroundType = backgroundImageType.background_type;
    }
    localStorage.setItem("billing_info", JSON.stringify(billingInfo));
  };

  useEffect(() => {
    const addMorePuppetConfirmation = localStorage.getItem("addMorePuppets");
    if (addMorePuppetConfirmation !== null) {
      setAddMorePuppetChecked(JSON.parse(addMorePuppetConfirmation));
    }

    const storeOrderConfirmation = localStorage.getItem("order_confirmation");
    if (storeOrderConfirmation !== null) {
      setOrderConfirmationIsChecked(JSON.parse(storeOrderConfirmation));
    }

    const storeTermsCondition = localStorage.getItem("terms_condition");
    if (storeTermsCondition !== null) {
      setTermsConditionChecked(JSON.parse(storeTermsCondition));
    }

    const slug = location?.state?.slug || undefined;
    const referenceData = data;
    var additional = "false";
    let apiVideoLength =  referenceData?.videoLength ? referenceData?.videoLength : videoLength.video_length ;
    let apiBackgroundType =  referenceData?.backgroundType ? referenceData?.backgroundType : backgroundImageType.background_type ;
    let apiDeliverFormat =  referenceData?.deliverFormat ? referenceData?.deliverFormat : deliverFormat.deliver_format ;
    setvideoLength({ video_length: apiVideoLength});
    setDeliverFormat({ deliver_format: apiDeliverFormat });
    if (slug) {
      if (location?.state?.selectedImage === "second") {
        additional = "true";
      }
      axios
      .get( ENDPOINT.PUPPET_LENGTH_PRICE + "/" + slug + "/" + apiVideoLength + "/" + apiBackgroundType + "/" + additional + "/" +  deliverFormat.deliver_format,  {})
      .then((response) => {
        let oldBillingInfo = referenceData;
        const updatedImage = response.data.data.profile_image_url || location?.state?.slug;
        if ( (oldBillingInfo.puppet_one && location?.state?.selectedImage === "first") || !oldBillingInfo.puppet_one ) 
        {
          oldBillingInfo.basicPrice = response.data.data.price;
          oldBillingInfo.taxes = response.data.data.taxes;
          oldBillingInfo.taxCalculate = response.data.data.tax_calculate;
          oldBillingInfo.deliverFormat = apiDeliverFormat;
          oldBillingInfo.deliverFormatPrice = response.data.data.deliver_format_price;
          oldBillingInfo.backgroundType = apiBackgroundType;
          oldBillingInfo.videoLength = apiVideoLength;
          oldBillingInfo.puppet_one = updatedImage;
          oldBillingInfo.puppet_one_id = response.data.data.id;
         
          setBasicPrice(oldBillingInfo.basicPrice);
          setTaxes(oldBillingInfo.taxes);
          setTaxCalculate({ type: oldBillingInfo.taxCalculate });
          setDeliverFormatPrice(oldBillingInfo.deliverFormatPrice);
          setBackgroundImageType({background_type: oldBillingInfo.backgroundType} );
          setPuppetImages1(updatedImage);
          setPuppetImages2(data?.puppet_two);
          setBackgroundImage(data?.backgroundImageURL)
         
          // Calculate total price
          let totalPrice = 0;
          let price = oldBillingInfo.basicPrice + oldBillingInfo.deliverFormatPrice;
          if(oldBillingInfo.taxCalculate === 'percentage') {
            const taxArray = taxCalc(price, oldBillingInfo.taxes);
            const tax = taxArray[0];
            totalPrice = taxArray[1];
            setTotalPrice(totalPrice);
            setTaxAmount(tax);
            oldBillingInfo.taxAmount = tax;
            oldBillingInfo.totalPrice = totalPrice;
          } else {
            totalPrice = price + oldBillingInfo.taxes;
            setTotalPrice(totalPrice);
            setTaxAmount(oldBillingInfo.taxes);
            oldBillingInfo.taxAmount = oldBillingInfo.taxes;
            oldBillingInfo.totalPrice = totalPrice;
          }
        
        } else {
          let oldBillingInfo = referenceData;
          oldBillingInfo.additionalPuppetPrice = response.data.data.additional_puppet_price;
          oldBillingInfo.taxes = response.data.data.taxes;
          oldBillingInfo.taxCalculate = response.data.data.tax_calculate;
          oldBillingInfo.deliverFormat = apiDeliverFormat;
          oldBillingInfo.deliverFormatPrice = response.data.data.deliver_format_price;
          oldBillingInfo.backgroundType = apiBackgroundType;
          oldBillingInfo.puppet_two = updatedImage;
          oldBillingInfo.videoLength = apiVideoLength;
          oldBillingInfo.puppet_two_id = response.data.data.id;

          setBasicPrice(data?.basicPrice);
          setTaxes(oldBillingInfo.taxes);
          setTaxCalculate({ type: oldBillingInfo.taxCalculate });
          setDeliverFormatPrice(oldBillingInfo.deliverFormatPrice);
          setBackgroundImageType({background_type: oldBillingInfo.backgroundType} );
          setPuppetImages1(data?.puppet_one);
          setPuppetImages2(updatedImage);
          setAdditionalPuppetPrice( response.data.data.additional_puppet_price );
          setBackgroundImage(data?.backgroundImageURL)

          let totalPrice = 0;
          let price = oldBillingInfo.basicPrice + oldBillingInfo.deliverFormatPrice + oldBillingInfo.additionalPuppetPrice;
          if(oldBillingInfo.taxCalculate === 'percentage'){
            const taxArray = taxCalc(price, oldBillingInfo.taxes);
            const tax = taxArray[0];
            totalPrice = taxArray[1];
            setTotalPrice(totalPrice);
            setTaxAmount(tax);
            oldBillingInfo.taxAmount = tax;
            oldBillingInfo.totalPrice = totalPrice;
          }else{
            totalPrice = price + oldBillingInfo.taxes;
            setTotalPrice(totalPrice);
            setTaxAmount(oldBillingInfo.taxes);
            oldBillingInfo.taxAmount = oldBillingInfo.taxes;
            oldBillingInfo.totalPrice = totalPrice;
          }
        }
        localStorage.setItem("billing_info", JSON.stringify(oldBillingInfo));
      })
      .catch((error) => {
        console.log(error);
      });

    } else {
      if (data?.puppet_one) {
        setPuppetImages1(data.puppet_one);
      }
      if (data?.puppet_two) {
        setPuppetImages2(data.puppet_two);
      }
      if (data?.additionalPuppetPrice) {
        setAdditionalPuppetPrice(data.additionalPuppetPrice);
      }
      setBasicPrice(data?.basicPrice);
      setTaxes(data?.taxes);      
      setTaxAmount(data?.taxAmount);
      setBackgroundImageType({background_type: data?.backgroundType} );
      setBackgroundImage(data?.backgroundImageURL)
      setTotalPrice(data?.totalPrice);
      setTaxCalculate({ type: data?.taxCalculate });
      setDeliverFormatPrice(data?.deliverFormatPrice);
    }

    // Get Video Background Price
    const backgroundImageId = location?.state?.backgroundImageID || undefined;
    if (backgroundImageId) {
      setBackgroundImageId(backgroundImageId);
      axios
        .get(ENDPOINT.CATEGORY_IMAGES_VIDEO_DETAIL + "/" + backgroundImageId + "/" + apiVideoLength,{}
        )
        .then((response) => {
          let oldBillingInfo = referenceData;
          oldBillingInfo.basicPrice = response.data.data.price;
          oldBillingInfo.backgroundType =  response.data.data.type;
          oldBillingInfo.backgroundImgId = response.data.data.id;
          oldBillingInfo.backgroundImageURL = response.data.data.picture_url;

          setBasicPrice(oldBillingInfo.basicPrice);
          setBackgroundImageType({ background_type: oldBillingInfo.backgroundType });
          setBackgroundImage(oldBillingInfo.backgroundImageURL);
          setDeliverFormat({ deliver_format: oldBillingInfo.deliverFormatPrice });
          setDeliverFormatPrice(oldBillingInfo.deliverFormatPrice);
          setTaxCalculate({ type: oldBillingInfo.taxCalculate });
          let totalPrice = 0;
          let price = 0;
          
          if(oldBillingInfo?.basicPrice){
            price = oldBillingInfo.basicPrice + oldBillingInfo.deliverFormatPrice;
            if(oldBillingInfo?.additionalPuppetPrice){
              price = price + oldBillingInfo.additionalPuppetPrice
            }
            if(oldBillingInfo.taxCalculate === 'percentage'){
              const taxArray = taxCalc(price, oldBillingInfo.taxes);
              const tax = taxArray[0];
              totalPrice = taxArray[1];
              setTotalPrice(totalPrice);
              oldBillingInfo.taxAmount = tax;
              setTaxAmount(tax);
              oldBillingInfo.totalPrice = totalPrice;
            } else {
              totalPrice = price + oldBillingInfo.taxes;
              setTotalPrice(totalPrice);
              setTaxAmount(oldBillingInfo.taxes);
              oldBillingInfo.taxAmount = oldBillingInfo.taxes;
              oldBillingInfo.totalPrice = totalPrice;
            }
          } else {
            let totalPrice = oldBillingInfo.basicPrice;
            setTotalPrice(totalPrice);
          }          
          localStorage.setItem("billing_info", JSON.stringify(oldBillingInfo));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const taxCalc = (price, tax) => {
    const taxA = (price * tax) / 100;
    return [taxA,(price + taxA)];
  }

  const puppetSelection = () => {
    localStorage.setItem("selectedOrder", "first");
    navigate("/puppet-profiles", { state: { selectedOrder: "first" } });
  };

  const removePuppetSelection = (e) => {

    const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
    // Check if the object exists and the key you want to remove exists
    if (localStorageOldData && localStorageOldData.hasOwnProperty("puppet_one")) {
      // Remove the key from the object
      delete localStorageOldData.puppet_one;
      delete localStorageOldData.puppet_one_id;
      setPuppetImages1(null);

      const isChecked = false;
      setAddMorePuppetChecked(isChecked);
      localStorage.removeItem("addMorePuppets");

      delete localStorageOldData.basicPrice;
      setBasicPrice(0);

      delete localStorageOldData.taxes;
      setTaxes(0);

      delete localStorageOldData.taxCalculate;
      setTaxCalculate({type: "amount"});

      delete localStorageOldData.deliverFormat;
      setDeliverFormat({ deliver_format: "online_viewing_link" });

      delete localStorageOldData.deliverFormatPrice;
      setDeliverFormatPrice(null);

      delete localStorageOldData.totalPrice;
      setTotalPrice(0);

      delete localStorageOldData.puppet_two;
      delete localStorageOldData.puppet_two_id;
      setPuppetImages2(null);

      delete localStorageOldData.additionalPuppetPrice;
      setAdditionalPuppetPrice(0);

      delete localStorageOldData.taxAmount;
      setTaxAmount(0);

      localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
    }
  };

  const addMorePuppet = (e) => {
    const isChecked = e.target.checked;
    setAddMorePuppetChecked(isChecked);
    localStorage.setItem("addMorePuppets", isChecked);
    if (!isChecked) {
      const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));

      // Check if the object exists and the key you want to remove exists
      if (localStorageOldData && localStorageOldData.hasOwnProperty("puppet_two")) {
        // Remove the key from the object
        delete localStorageOldData.puppet_two;
        delete localStorageOldData.puppet_two_id;
        setPuppetImages2(null);
        // Store the updated object back into localStorage
        localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
      }
    }
  };

  const additionalPuppetSelection = () => {
    localStorage.setItem("selectedOrder", "second");
    navigate("/puppet-profiles", { state: { selectedOrder: "second" } });
  };

  const removeAdditionalPuppetSelection = (e) => {
    const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
    if (localStorageOldData && localStorageOldData.hasOwnProperty("puppet_two")) {
      delete localStorageOldData.puppet_two;
      delete localStorageOldData.puppet_two_id;
      setPuppetImages2(null);
      
      delete localStorageOldData.additionalPuppetPrice;
      setAdditionalPuppetPrice(0);

      let totalPrice = 0;
      let price = localStorageOldData.basicPrice + localStorageOldData.deliverFormatPrice ;
      setTaxCalculate({ type: localStorageOldData.taxCalculate });
      if(localStorageOldData.taxCalculate === 'percentage'){
        const taxArray = taxCalc(price, localStorageOldData.taxes);
        const tax = taxArray[0];
        totalPrice = taxArray[1];
        setTotalPrice(totalPrice);
        setTaxAmount(tax);
        localStorageOldData.totalPrice = totalPrice;
        localStorageOldData.taxAmount = tax;
      }else{
        totalPrice = price + localStorageOldData.taxes;
        setTotalPrice(totalPrice);
        setTaxAmount(localStorageOldData.taxes);
        localStorageOldData.totalPrice = totalPrice;
      }

      // Store the updated object back into localStorage
      localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
    }
  };

  const selectBackground = () => {
    navigate("/category");
  };

  const removeBackgroundImage = (e) => {
   
    const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
    // // Check if the object exists and the key you want to remove exists
    if (localStorageOldData && localStorageOldData.hasOwnProperty("backgroundImageURL")) {
      
      delete localStorageOldData.backgroundImageURL;
      setBackgroundImage(undefined);

      delete localStorageOldData.backgroundType;
      setBackgroundImageType({background_type: "image"});
      localStorageOldData.backgroundType = 'image';

      delete localStorageOldData.backgroundImgId;
      setBackgroundImageId(0)
  
      localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
     
      const vlength = localStorageOldData.videoLength;
      const bType = localStorageOldData.backgroundType;
     
      axios
        .get( ENDPOINT.LENGTH_PRICE + "/" + vlength + "/" + bType, {}
        )
        .then((response) => {
          const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
          localStorageOldData.backgroundType = response.data.data.file_type;
          localStorageOldData.basicPrice = response.data.data.price;
          setBackgroundImageType({ background_type: localStorageOldData.backgroundType});
          setBasicPrice(localStorageOldData.basicPrice);
          setTaxCalculate({ type: localStorageOldData.taxCalculate });
          
          let totalPrice = 0;
          let price = 0;
          price = localStorageOldData.basicPrice + localStorageOldData.deliverFormatPrice;
          if(localStorageOldData?.additionalPuppetPrice){
            price = price + localStorageOldData.additionalPuppetPrice
          }
          if(localStorageOldData.taxCalculate === 'percentage'){
            const taxArray = taxCalc(price, localStorageOldData.taxes);
            const tax = taxArray[0];
            totalPrice = taxArray[1];
            setTotalPrice(totalPrice);
            setTaxAmount(tax);
            localStorageOldData.totalPrice = totalPrice;
            localStorageOldData.taxAmount = tax;
          }else{
            totalPrice = price + localStorageOldData.taxes;
            setTotalPrice(totalPrice);
            setTaxAmount(localStorageOldData.taxes);
            localStorageOldData.totalPrice = totalPrice;
          }
          localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getRaidoLengthValue = (e) => {    
    const { name, value } = e.target;
    setvideoLength({video_length:value });
    
    const referenceData = JSON.parse(localStorage.getItem("billing_info"));
    referenceData.videoLength = Number(value);
    localStorage.setItem("billing_info", JSON.stringify(referenceData));
    axios
      .get(ENDPOINT.LENGTH_PRICE + "/" + Number(value) + "/" + backgroundImageType.background_type,{})
      .then((response) => {
        const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
        localStorageOldData.backgroundType = response.data.data.file_type;
        localStorageOldData.basicPrice = response.data.data.price;

        setBackgroundImageType({ background_type: localStorageOldData.backgroundType});
        setBasicPrice(localStorageOldData.basicPrice);
        setTaxCalculate({ type: localStorageOldData.taxCalculate });
        
        let totalPrice = 0;
        let price = 0;
        price = localStorageOldData.basicPrice + localStorageOldData.deliverFormatPrice;
        if(localStorageOldData?.additionalPuppetPrice){
          price = price + localStorageOldData.additionalPuppetPrice
        }
        if(localStorageOldData.taxCalculate === 'percentage'){
          const taxArray = taxCalc(price, localStorageOldData.taxes);
          const tax = taxArray[0];
          totalPrice = taxArray[1];
          setTotalPrice(totalPrice);
          setTaxAmount(tax);
          localStorageOldData.totalPrice = totalPrice;
          localStorageOldData.taxAmount = tax;
        } else {
          totalPrice = price + localStorageOldData.taxes;
          setTotalPrice(totalPrice);
          setTaxAmount(localStorageOldData.taxes);
          localStorageOldData.totalPrice = totalPrice;
        }
        localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDeliverFormatValue = (e) => {
    const { name, value } = e.target;
    setDeliverFormat({ deliver_format: value } );

    const referenceData = JSON.parse(localStorage.getItem("billing_info"));
    referenceData.deliverFormat = value;
    localStorage.setItem("billing_info", JSON.stringify(referenceData));
  
    axios
    .get( ENDPOINT.DELIVERY_FORMAT_PRICE + "/" +  value, {} )
    .then((response) => {
      const localStorageOldData = JSON.parse(localStorage.getItem("billing_info"));
      localStorageOldData.deliverFormatPrice = response.data.data.deliver_format_price;
      setDeliverFormatPrice(localStorageOldData.deliverFormatPrice);
      setDeliverFormat({ deliver_format: value })

      let totalPrice = 0;
      let price = 0;
      price = localStorageOldData.basicPrice + localStorageOldData.deliverFormatPrice;
      if(localStorageOldData?.additionalPuppetPrice){
        price = price + localStorageOldData.additionalPuppetPrice
      }
      if(localStorageOldData.taxCalculate === 'percentage'){
        const taxArray = taxCalc(price, localStorageOldData.taxes);
        const tax = taxArray[0];
        totalPrice = taxArray[1];
        setTotalPrice(totalPrice);
        setTaxAmount(tax);
        localStorageOldData.totalPrice = totalPrice;
        localStorageOldData.taxAmount = tax;
      } else {
        totalPrice = price + localStorageOldData.taxes;
        setTotalPrice(totalPrice);
        setTaxAmount(localStorageOldData.taxes);
        localStorageOldData.totalPrice = totalPrice;
      }
      localStorage.setItem("billing_info", JSON.stringify(localStorageOldData));
    })
    .catch((error) => {
      console.log(error);
    });
    


  };

  const handlesetTermsConditionChange = (e) => {
    const isTermsConditionChecked = e.target.checked;
    setTermsConditionChecked(isTermsConditionChecked);
    localStorage.setItem("terms_condition", isTermsConditionChecked);
  };  

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
    zip: Yup.string().required('Zip Code is required'),
    phone: Yup.string().required('Phone number is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    name_of_recipient: Yup.string().required('Name of Recipient is required'),
    occasion: Yup.string().required('Occasion is required'),
    special_message: Yup.string().required('Special Message is required'),
    order_confirmation: Yup.boolean().oneOf([true], 'Order conformation  must be accepted'),
    terms_condition: Yup.boolean().oneOf([true], 'Terms and Conditions must be accepted'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      address: data.address || '',
      city: data.city || '',
      state: data.state || '',
      zip: data.zip || '',
      phone: data.phone || '',
      cell_phone: data.cell_phone || '',
      email: data.email || '',
      name_of_recipient: data.name_of_recipient || '',
      occasion: data.occasion || '',
      special_message: data.special_message || '',
      additional_message: data.additional_message || '',
      order_confirmation:orderConfirmationChecked,
      terms_condition: termsConditionChecked,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const postData = {
        'data' : JSON.parse(localStorage.getItem("billing_info")),
        'order_id' : localStorage.getItem("order_id") ? localStorage.getItem("order_id") :  "0",
      };
      axios.post(ENDPOINT.ORDER_CONFIRM, postData, {})
      .then((response) => {
        if(response.data.status){
          toast(response.data.message);
          localStorage.setItem("billing_info", JSON.stringify(response.data.billingInfo));
          localStorage.setItem("order_id", response.data.order_id);
          localStorage.setItem("invoice_number", response.data.invoice_number);
          // Handle the response data as needed
          navigate("/order-summery");
        }else{
         
          toast(response.data.message);
        }
       
      })
      .catch((error) => {
        toast(error.message);
      });

      
    },
  });

  return (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
      <ToastContainer />
        <Formik>
          <form onSubmit={formik.handleSubmit}    >
            <div className="container">
              <div className="odr_frm">
                <FormLogo title="Order Form" />

                <div className="of_bx_alfa">
                  <div className="row">
                    <div className="col mb-4">
                      <div className="d-flex">
                        <label>Billing Information</label>
                        {/* <input type="text" /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Purchase Date:</label>{" "}
                        { formatDate(new Date(purchaseDate), 3) }
                        <input
                          type="hidden"
                          name="purchase_date"
                          onKeyUp={(e) => handleLoclalStorage(e)}
                          defaultValue={data.purchase_date}
                          value={purchaseDate}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Delivery Date:</label>{" "}
                        { formatDate(new Date(deliveryDate), 3) }
                        <input
                          type="hidden"
                          name="delivery_date"
                          onKeyUp={(e) => handleLoclalStorage(e)}
                          defaultValue={data.delivery_date}
                          value={deliveryDate}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>First Name:</label>{" "}
                        <input
                          type="text"
                          name="first_name"
                          onKeyUp={(e) => handleLoclalStorage(e)}
                          defaultValue={data.first_name}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.first_name}
                        />
                      </div>
                      {formik.touched.first_name && formik.errors.first_name ? (
                          <div className="text-danger">{formik.errors.first_name}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Last Name:</label>{" "}
                        <input
                          type="text"
                          name="last_name"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.last_name}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.last_name}
                        />
                           
                      </div>
                      {formik.touched.last_name && formik.errors.last_name ? (
                          <div className="text-danger">{formik.errors.last_name}</div>
                        ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5">
                      <div className="d-flex mb-4">
                        <label>Address:</label>{" "}
                        <input
                          type="text"
                          name="address"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.address}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.address}
                        />
                      </div>
                      {formik.touched.address && formik.errors.address ? (
                          <div className="text-danger">{formik.errors.address}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-3">
                      <div className="d-flex mb-4">
                        <label>City:</label>{" "}
                        <input
                          type="text"
                          name="city"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.city}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.city}
                        />
                          
                      </div>
                      {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger">{formik.errors.city}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-2">
                      <div className="d-flex mb-4">
                        <label>State:</label>{" "}
                        <input
                          type="text"
                          name="state"
                          onKeyUp={handleLoclalStorage}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.state}
                        />
                          
                      </div>
                      {formik.touched.state && formik.errors.state ? (
                          <div className="text-danger">{formik.errors.state}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-2">
                      <div className="d-flex mb-4">
                        <label>Zip:</label>{" "}
                        <input
                          type="text"
                          name="zip"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.zip}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.zip}
                        />
                           
                      </div>
                      {formik.touched.zip && formik.errors.zip ? (
                          <div className="text-danger">{formik.errors.zip}</div>
                        ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Phone:</label>{" "}
                        <input
                          type="text"
                          name="phone"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.phone}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.phone}
                        />
                          
                      </div>
                      {formik.touched.phone && formik.errors.phone ? (
                          <div className="text-danger">{formik.errors.phone}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Cell Phone:</label>{" "}
                        <input
                          type="text"
                          name="cell_phone"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.cell_phone}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.cell_phone}
                        />
                          
                      </div>
                      {formik.touched.cell_phone && formik.errors.cell_phone ? (
                          <div className="text-danger">{formik.errors.cell_phone}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-4">
                      <div className="d-flex mb-4">
                        <label>Email:</label>{" "}
                        <input
                          type="text"
                          name="email"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.email}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.email}
                        />
                          
                      </div>
                      {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>
                  </div>
                </div>

                <div className="of_bx_alfa">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Name of Recipient:</label>{" "}
                        <input type="text"
                          name="name_of_recipient"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.name_of_recipient}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.name_of_recipient}
                        />
                           
                      </div>
                      {formik.touched.name_of_recipient && formik.errors.name_of_recipient ? (
                          <div className="text-danger">{formik.errors.name_of_recipient}</div>
                        ) : null}
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex mb-4">
                        <label>Occasion:</label>{" "}
                        <input
                          type="text"
                          name="occasion"
                          onKeyUp={handleLoclalStorage}
                          defaultValue={data.occasion}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.occasion}
                        />
                          
                      </div>
                      {formik.touched.occasion && formik.errors.occasion ? (
                          <div className="text-danger">{formik.errors.occasion}</div>
                        ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col mb-4">
                      <label>Your Special Message:</label>
                      <textarea
                        rows={3}
                        cols={30}
                        name="special_message"
                        onKeyUp={handleLoclalStorage}
                        defaultValue={data.special_message}
                        onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.special_message}
                        />
                            
                    </div>
                    {formik.touched.special_message && formik.errors.special_message ? (
                          <div className="text-danger">{formik.errors.special_message}</div>
                        ) : null}
                  </div>

                  <div className="row">
                    <div className="col mb-4">
                      <div className="d-flex">
                        <label>Click Here for Multiple Puppet Selection:</label>{" "}
                        <input
                          type="checkbox"
                          name="addMorePuppets"
                          checked={addMorePuppetChecked}
                          onChange={addMorePuppet}
                          disabled={!puppetImages1}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="blue_box mb-4">
                        <label>Puppet:</label>

                        {puppetImages1 && <img src={puppetImages1}  width="138px" alt="pupprt1" />}
                        {puppetImages1 ? (
                          <div className="d-flex mb-4 mt-2">
                            <input className="spl_btn" type="button" defaultValue="Cancel" onClick={removePuppetSelection} />
                          </div>
                        ) : (
                          <button className="odrFormSelection" onClick={puppetSelection}><label>+</label></button>
                        )}
                      </div>
                    </div>

                    {addMorePuppetChecked && puppetImages1 ? (
                      <div className="col-sm-4">
                        <div className="blue_box mb-4">
                          <label>Additional Puppet: </label>
                          {puppetImages2 && <img src={puppetImages2} width="138px" alt="pupprt2" />}

                          {puppetImages2 ? (
                            <div className="d-flex mb-4 mt-2">
                              <input  className="spl_btn" 
                                type="button"
                                defaultValue="Cancel"
                                onClick={removeAdditionalPuppetSelection}
                              />
                            </div>
                          ) : (
                            <button
                              className="odrFormSelection"
                              onClick={additionalPuppetSelection}
                            >
                              <label>+</label>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                  <div className="row mb-2">
                    <div className="col">
                      <div className="d-flex">
                        <label>Background: </label>
                      </div>
                      <div className="blue_box">
                      {backgroundImage && (
                          backgroundImageType.background_type === 'video' ? (
                            <video width="320" height="240" controls>
                              <source src={backgroundImage} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img src={backgroundImage} alt="pupprt1" />
                          )
                      )}
                    
                        {backgroundImage ? (
                          <div className="d-flex mb-4 mt-2">
                            <input className="spl_btn" 
                              type="button"
                              defaultValue="Cancel"
                              onClick={removeBackgroundImage}
                            />
                          </div>
                        ) : (
                          <button
                            className="odrFormSelection bgSelection"
                            onClick={selectBackground}
                          >
                            <label>+</label>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  { puppetImages1 && 
                    <div className="row">
                      <div className="col">
                        <div className="d-flex mb-4">
                          <label>Video Length: </label>
                          <input
                            type="radio"
                            id="v_radio1"
                            name="video_length"
                            value={30}
                            onChange={(e) => getRaidoLengthValue(e)}
                            defaultChecked={videoLength.video_length === 30} 
                          />
                          &nbsp; <label>(30 Sec.) </label>
                          <input
                            type="radio"
                            id="v_radio2"
                            name="video_length"
                            value={45}
                            onChange={(e) => getRaidoLengthValue(e)}
                            defaultChecked={videoLength.video_length === 45} 
                          />
                          &nbsp; <label>(45 Sec.) </label>
                          <input
                            type="radio"
                            id="v_radio3"
                            name="video_length"
                            value={60}
                            onChange={(e) => getRaidoLengthValue(e)}
                            defaultChecked={videoLength.video_length === 60} 
                          />
                          &nbsp; <label>(1 min.)</label>
                          <input
                            type="radio"
                            id="v_radio4"
                            name="video_length"
                            value={120}
                            onChange={(e) => getRaidoLengthValue(e)}
                            defaultChecked={videoLength.video_length === 120} 
                          />
                          &nbsp; <label>(2 min.)</label>
                        </div>
                      </div>
                    </div>
                  } { puppetImages1 && 
                    <div className="row">
                      <div className="col">
                        <div className="d-flex mb-4">
                          <label>Deliver Format:</label>
                          <input
                            type="radio"
                            id="deliver_radio1"
                            name="delivery_format"
                            defaultChecked={deliverFormat.deliver_format === 'online_viewing_link'} 
                            value={"online_viewing_link"}
                            onChange={(e) => getDeliverFormatValue(e)}
                          />
                          &nbsp; <label>(Online Viewing Link) </label>
                          <input
                            type="radio"
                            id="deliver_radio2"
                            name="delivery_format"
                            defaultChecked={deliverFormat.deliver_format === 'digital_download'} 
                            value={"digital_download"}
                            onChange={(e) => getDeliverFormatValue(e)}
                          />
                          &nbsp; <label>(Digital Download) </label>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="row">
                    <div className="col mb-4">
                      <label>Additional Information/Special Requests:</label>
                      <textarea
                        rows={3}
                        cols={30}
                        name="additional_message"
                        onKeyUp={handleLoclalStorage}
                        defaultValue={data.additional_message}
                        onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          value={formik.values.additional_message}
                        />
                           
                    </div>
                    {formik.touched.additional_message && formik.errors.additional_message ? (
                          <div className="text-danger">{formik.errors.additional_message}</div>
                        ) : null}

                  </div>

                  <div className="row">
                    <div className="col-sm-9 mb-4">
                      <div className="d-flex align-items-start">                        
                        <p><input
                          type="checkbox"
                          name="order_confirmation"
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          checked={formik.values.order_confirmation}
                        />
                          &nbsp; ORDER CONFIRMATION: Please review your order &amp; Click
                          Here to verify that all information on your OrderForm is
                          correct to proceed to checkout.
                        </p>
                      </div>
                      
                    </div>
                   
                  </div>
                  {formik.touched.order_confirmation && formik.errors.order_confirmation ? (
                          <div className="text-danger">{formik.errors.order_confirmation}</div>
                        ) : null}
                </div>

                <div className="of_bx_alfa">
                  <div className="row">
                    <div className="col-sm-7">
                      <label className=" mb-4">
                        Your PuppetGram Video Invoice Breakdown:
                      </label>
                      {/* {backgroundImageType?.background_type?.charAt(0).toUpperCase() + backgroundImageType?.background_type?.slice(1) } */}
                      <div className="d-flex justify-content-between">
                        <label> Puppet + {backgroundImageType?.background_type || "Image"} ({videoLength.video_length} Sec.):</label>{" "} <label>{ENDPOINT.DOLLER_SIGN}{basicPrice || 0}</label>
                      </div>
                    
                      { (puppetImages2) && (
                          <div className="d-flex justify-content-between">
                            <label>Additional Puppet Price:</label>{" "}<label>{ENDPOINT.DOLLER_SIGN}{additionalPuppetPrice || ""}</label>
                          </div>
                        )}

                      { (deliverFormatPrice !== 0) && ( 
                        <div className="d-flex justify-content-between">
                          <label>Video Deliver Option Price:</label>{" "}<label>{ENDPOINT.DOLLER_SIGN}{deliverFormatPrice || 0}</label>
                        </div>
                      )}
                      { (additionalPuppetPrice !== 0 || deliverFormatPrice !== 0) && (
                        <>
                          <hr/>
                          <div className="d-flex justify-content-between">
                            <label></label><label>{ENDPOINT.DOLLER_SIGN}{(basicPrice + additionalPuppetPrice + deliverFormatPrice) || 0}</label>
                          </div>
                        </>                        
                      )}
                      {(taxAmount !== 0) && 
                        <>
                          <hr/>
                          <div className="d-flex justify-content-between">
                            <label>Taxes ({taxes}{taxCalculate.type === 'amount' ? '' : (<span>%</span>)}): </label> <label> {ENDPOINT.DOLLER_SIGN}{taxAmount?.toFixed(2) || 0}</label>
                          </div>
                      </>                        
                      }
                      <hr/>
                      <div className="d-flex justify-content-between">
                        <label>Total Price:</label> <label>{ENDPOINT.DOLLER_SIGN}{totalPrice?.toFixed(2) || 0}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <h4 className="text-center text-uppercase mt-4 fw-bold">
                  Thank You for choosing
                  <br /> PuppetGram VideoGram Entertainment Service
                </h4>

                <div className="ordr_frm_ppt">
                  <img src={ordr_frm_ppt} alt="pupprt1" />
                </div>

              </div>

              <div className="odr_frm">
                <div className="row">
                  <div className="col-md-9 offset-md-3">
                    <div className="d-flex justify-content-between align-items-end">
                      <div className="of_logo">
                        <img src={logo_name} alt="pupprt1" />
                      </div>
                      <h3 className="fw-bold colorBlack">Terms and Conditions</h3>
                    </div>
                    <div className="of_bx_alfa">
                      <p>Clients must pay 100% of (non-refundable) invoice before video production can begin.
All orders require 5 working days to produce a finished video. If finished video is needed in less than 5 working days, client agrees to pay additional rush fees. Cost of video is based on number of puppets used, video length, background selection type, and delivery format. Cancellation policy: We offer a 24-hour cancellation of a normal video (5 days) with a forfeit of 50% of the deposit for production costs. Client agrees to all terms and conditions.</p>
                      <div className="d-flex justify-content-end align-items-start">
                        <p>Click here to accept terms and conditions&nbsp;
                        <input
                          type="checkbox"
                          name="terms_condition"
                          // checked={termsConditionChecked}
                          onClick={handlesetTermsConditionChange}
                          onChange={formik.handleChange} 
                          onBlur={formik.handleBlur} 
                          checked={formik.values.terms_condition}
                        /></p>
                            {formik.touched.terms_condition && formik.errors.terms_condition ? (
                          <div className="text-danger">{formik.errors.terms_condition}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tc_ppt">
                  <img src={of_ftr_grph} alt="pupprt1" />
                </div>
                <div className="of_bx_alfa">
                  <div className="row">
                    <div className="col-sm-12 mb-4">
                      {puppetImages1 ? (
                        <div className="d-flex mb-4 align-items-center justify-content-center" >
                          <input type="submit" defaultValue="Place Order" value="Add to Cart"/>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Formik>
      </section>
    </>
  );
};

export default OrderNow;
