import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ENDPOINT from "../../constants/api-endpoints";
import { Link } from "react-router-dom";
import LoadingSpinner from "../elements/LoadingSpinner";

const MyFavorite = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [myFavoritePuppet, setMyFavoritePuppet] = useState([]);

  useEffect(() => {
    axios
      .get(ENDPOINT.MY_FAVORITE_PUPPET, {})
      .then((response) => {
        setIsLoading(false);
        setMyFavoritePuppet(response.data.myFavoritePuppet);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  const renderData = (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
        <div className="container-fluid">
          <nav className="main-nav">
            <ul className="favorite">
              <li>
                <Link to="/my-favorite" className="active">Puppet</Link>
              </li>
              <li>
                <Link to="/my-favorite-background" >Background</Link>
              </li>
            </ul>
          </nav>
        
          <div className="row">
            {myFavoritePuppet.length > 0 ? myFavoritePuppet?. map((data) => (
              <div className="col-sm-4">
                <div className="pfl_pc_bx">
                  <img src={data.file_url} alt="thumbnail" />
                </div>
              </div>
              )) : 
              (
                <div className="noRecords">No Records</div>
              )
              }
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default MyFavorite;
