import React, { useState, useRef, useEffect } from 'react';
import { useParams , Link, useLocation} from "react-router-dom";
import axios from 'axios';
import FormLogo from '../elements/FormLogo';
import ENDPOINT from '../../constants/api-endpoints';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import { useReactToPrint } from 'react-to-print';
import printer_icon from "../../css/images/printer-icon-1027.png";
import download_icon from "../../css/images/icon-download.jpg";
import view_icon from "../../css/images/icon-view.jpg";
import formatDate from "../elements/DateFormat";
import LoadingSpinner from "../elements/LoadingSpinner";
const OrderDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  let decodedId = atob(id);
  const [orderDetail, setOrderDetail] = useState({});
  const [orderDetailId, setOrderDetailId] = useState();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    axios
      .get(ENDPOINT.ORDER_DETAIL + "/" + decodedId, {})
      .then((response) => {
        setIsLoading(false);
        setOrderDetailId(response.data.data.id);
        setOrderDetail(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []); 
  const renderData = (
    <>
      <section className="full_width common_heading" id="scrollContentTarget">
      <ToastContainer />
        <div className="container">
          <div className="odr_frm">
            <div className="odr_frm">
              <FormLogo title="Order Detail" /> 
              <div className="of_bx_alfa orderDetailsBG" ref={componentRef}>
                  <div className="row">
                    <div className="col mb-4">
                      <div className="d-flex">
                        <label>Order Details</label>
                      </div>
                    </div>
                    <div className="col mb-8">
                      <div className="d-flex " style={{ float:"right"}} >
                        <button onClick={handlePrint}><img src={printer_icon} alt="Print Invoice" title="Print Invoice" width="30px" height="auto"/></button>
                      </div>
                      {orderDetail?.online_viewing_link && (
                        <div className="d-flex " style={{ float:"right", padding: "0 4px 0 4px"}} >
                          <a href={ orderDetail?.online_viewing_link } target="_blank">
                            <img src={view_icon} alt="Watch Video" title="Watch Video" width="35px" height="auto"/>
                          </a>
                        </div>
                      )}
                      {orderDetail?.digital_download && (
                        <div className="d-flex " style={{ float:"right", padding: "0 4px 0 4px"}} >
                          <a href={ orderDetail?.digital_download } download target="_blank">
                            <img src={download_icon} alt="View & download Video" title="View & download Video" width="35px" height="auto"/>
                          </a>
                        </div>
                      )}
                    </div>
                  </div> 
                  <div className='row'>
                    <div className="container colorBlack">
                      <div className="row">
                        {/* BEGIN INVOICE */}
                        <div className="col-sm-12">
                          <div className="grid invoice">
                            <div className="grid-body">
                              <div className="invoice-title">
                                <div className="row">
                                  <div className="col-sm-12">
                                    <h3 className='colorBlack'> <label>Invoice Number:</label> <span className="small">{orderDetail?.invoice_number}</span> </h3>
                                  </div>
                                  <div className="col-sm-6"><label>Purchase Date:</label>{ formatDate(new Date(orderDetail?.purchase_date), 3) }</div>
                                  <div className="col-sm-6"><label>Delivery Date:</label>{ formatDate(new Date(orderDetail?.delivery_date), 3) }</div>
                                </div>
                              </div>
                              <hr />

                              <div className="row">
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Name: </strong> { orderDetail.order_record?.first_name } { orderDetail.order_record?.last_name }
                                    <br />
                                    <strong>Email: </strong> { orderDetail.order_record?.email }
                                    <br />
                                    <abbr title="Phone"><strong>Phone: </strong>:</abbr> { orderDetail.order_record?.phone } , { orderDetail.order_record?.cell_phone }
                                  </address>
                                </div>
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Address:</strong> { orderDetail.order_record?.address }
                                    <br />
                                    { orderDetail.order_record?.state } ,  { orderDetail.order_record?.city } ,  { orderDetail.order_record?.zip }
                                    <br />
                                  </address>
                                </div>
                              </div>
                              
                              <div className="row">
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Recipient: </strong> { orderDetail?.name_recipient }
                                    <br />
                                    <strong>Occasion: </strong> { orderDetail.order_record?.occasion }
                                    <br />
                                    <strong>Special Message:</strong> { orderDetail.order_record?.special_message }
                                  </address>
                                </div>
                                { orderDetail.order_record?.additional_message &&
                                <div className="col-sm-6">
                                  <address>
                                    <strong>Additional Information:</strong> { orderDetail.order_record?.additional_message }
                                    <br />
                                    <strong>Delivery Type:</strong> { orderDetail.order_record?.deliverFormat.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }
                                  </address>
                                </div>
                                }
                              </div>
                              <hr />

                              <div className="row colorBlack">
                                <div className="col-md-12 mobileScroll">
                                  <h3 className='colorBlack'>ORDER SUMMARY</h3>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr className="line">
                                        <td className=""><strong>Profile</strong></td>
                                        <td className=""><strong>Data</strong></td>
                                        <td className=""><strong>Background</strong></td>
                                        <td className="" style={{ textAlign: "right" }}><strong></strong></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      
                                      <tr>
                                        <td><img src={ orderDetail.order_record?.puppet_one}  style={{ width: "100px" }}/></td>
                                        <td>Puppet + {orderDetail.order_record?.backgroundType.charAt(0).toUpperCase() + orderDetail.order_record?.backgroundType.slice(1)}</td>
                                        <td className="">
                                          {orderDetail.order_record?.backgroundType === 'video' ? (
                                          <video  width="100" height="100" >
                                            <source src={orderDetail.order_record?.backgroundImageUR} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                          ) : (
                                            <img src={ orderDetail.order_record?.backgroundImageURL} style={{ width: "100px" }}/>
                                          )}
                                          </td>
                                        <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{ orderDetail.order_record?.basicPrice}</td>
                                      </tr>

                                      { orderDetail.order_record?.puppet_two && 
                                      <tr>
                                        <td><img src={ orderDetail.order_record?.puppet_two}  style={{ width: "100px" }}/></td>
                                        <td>Additional Puppet</td>
                                        <td className="">&nbsp;</td>
                                        <td className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{ orderDetail.order_record?.additionalPuppetPrice}</td>
                                      </tr>
                                      }

                                      { orderDetail.order_record?.deliverFormatPrice !== 0 && 
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>Video Deliver Option Price</td>
                                        <td className="">&nbsp;</td>
                                        <td  className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{ orderDetail.order_record?.deliverFormatPrice}</td>
                                      </tr>
                                      }

                                      { (orderDetail.order_record?.additionalPuppetPrice !== 0 || orderDetail.order_record?.deliverFormatPrice !== 0 ) && 
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td className=""><strong>Subtotal</strong></td>
                                        <td  className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{(orderDetail.order_record?.basicPrice + (orderDetail.order_record?.additionalPuppetPrice ? orderDetail.order_record?.additionalPuppetPrice : 0) + (orderDetail.order_record?.deliverFormatPrice ? orderDetail.order_record?.deliverFormatPrice : 0)) || 0}</td>
                                      </tr>
                                      }

                                      { (orderDetail.order_record?.couponDiscount) && 
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                        <td className=""><strong>Promocode Discount(-)</strong></td>
                                        <td  className="" style={{ textAlign: "right" }}>{ENDPOINT.DOLLER_SIGN}{(orderDetail.order_record?.couponDiscount) || 0}</td>
                                      </tr>
                                      }
                                      {(orderDetail.order_record?.taxAmount !== 0) && 
                                        <tr>
                                          <td colSpan={2} />
                                          <td className=""><strong>Taxes ( { orderDetail.order_record?.taxes }{orderDetail.order_record?.taxCalculate == 'percentage' ? '%' :''})  </strong></td>
                                          <td className="" style={{ textAlign: "right" }}><strong>&nbsp;&nbsp;&nbsp;{ENDPOINT.DOLLER_SIGN}{ orderDetail.order_record?.taxAmount.toFixed(2) } </strong></td>
                                        </tr>
                                      }
                                      <tr>
                                        <td colSpan={2}></td>
                                        <td className=""><strong>Total</strong></td>
                                        <td className="" style={{ textAlign: "right" }}><strong>{ENDPOINT.DOLLER_SIGN}{  orderDetail.order_record?.totalPrice.toFixed(2) }</strong></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <hr/>

                              <div className="row">
                                <div className="col-md-12 mobileScroll">
                                  <h3 className='colorBlack'>PAYMENT INFORMATION</h3>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr className="line">
                                        <td className=""><strong>Reference ID</strong></td>
                                        <td className=""><strong>Payment Getway</strong></td>
                                        <td className=""><strong>Payment Status</strong></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="">{orderDetail.transaction_id ? orderDetail.transaction_id  : 'NULL'}</td>
                                        <td className="">{orderDetail.payment_get_way ? ((orderDetail.payment_get_way === 'Stripe') ? "Credit Card" : orderDetail.payment_get_way) : 'NULL'}</td>
                                        <td className="">{orderDetail.payment_status ? orderDetail.payment_status  : 'NULL'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* END INVOICE */}
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          </div>
        </div>
      </section>
    </>
  );
  return (
    <>
        {isLoading ? <LoadingSpinner /> : renderData}
    </>
  );
};

export default OrderDetail;
